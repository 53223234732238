import React from 'react'

// Style
import { AproveiteTodoPotencialSection } from './style'

const AproveiteTodoPotencial = () => {
  return (
    <AproveiteTodoPotencialSection id='aprofeite-todo-potencial' className='d-flex align-items-end align-items-md-center bg-white'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-6 col-xl-5 offset-xl-7 pr-0'>
            <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--400 pb-4 pb-md-0'>Aproveite todo potencial do seu terreno e financie sua obra com o Inter.</h3>
          </div>
        </div>
      </div>
    </AproveiteTodoPotencialSection>
  )
}

export default AproveiteTodoPotencial
