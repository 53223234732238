import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const PerguntasFrequentes = styled.section`
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 56px 0;
  }

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  .summary-details {

    .summary {
      .summary-content {
        align-items: center;
        p {
          color: ${grayscale[500]}
        }
        /* span {
          color: ${grayscale[200]}
        } */
      }
    }
  }

  .search-input {
    background: ${grayscale[100]};
  }
`
