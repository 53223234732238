import React from 'react'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import {
  Container,
  CloseButton,
  ScrollDiv,
  BoxConditions,
  StarterTimeline,
  TitleDiv,
  DotSymbol,
  BoxDescription,
  MonthTax,
  TitleDescription,
  AlertBox,
 } from './style'

import simuladorData from '../assets/data/simulationData.json'
import { ISimuladorConstrucasa, ILiberacaoDeCredito, ISimuladorMensal, IValorDescription } from './types'

type modalSimuladorDeParcelas = {
  closeModal: () => void;
}

function ModalSimuladorDeParcelas ({ closeModal }: modalSimuladorDeParcelas) {
  const data: ISimuladorConstrucasa = simuladorData

  return (
    <>
      <Container className='container'>
        <div className='row'>
          <div className='col d-flex justify-content-end px-0'>
            <CloseButton className='border-0 bg-transparent px-0 pb-4' onClick={closeModal}>
              <Close width='24' height='24' color='orange--extra' />
            </CloseButton>
          </div>
          <ScrollDiv>
            <h2 className='fs-32 lh-40 fw-600 text-grayscale--500 pb-4'>Simulação de parcelas e taxas</h2>
            <p className='fs-14 lh-18 text-grayscale--400 pb-3 pb-md-4'>O exemplo abaixo é meramente ilustrativo e se refere às seguintes condições:</p>
            <div className='col d-md-flex flex-md-column align-items-md-center'>
              <BoxConditions className=''>
                <div className='d-flex justify-content-between'>
                  <span className='fs-12 lh-14 text-grayscale--400'>Valor do terreno</span>
                  <span className='fs-12 lh-16 fw-600 text-grayscale--500'>{data.valorDoTerreno}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='fs-12 lh-14 text-grayscale--400'>Valor total da obra</span>
                  <span className='fs-12 lh-16 fw-600 text-grayscale--500'>{data.valorTotalDaObra}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='fs-12 lh-14 text-grayscale--400'>Prazo da obra</span>
                  <span className='fs-12 lh-16 fw-600 text-grayscale--500'>{data.prazoDaObra}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span className='fs-12 lh-14 text-grayscale--400'>Prazo do financiamento</span>
                  <span className='fs-12 lh-16 fw-600 text-grayscale--500'>{data.prazoFinanciamento}</span>
                </div>
                <hr className='mt-1 mb-2' />
                <div className='d-flex justify-content-between'>
                  <span className='fs-12 lh-14 fw-700 text-grayscale--500'>Liberações de crédito</span>
                  <span className='fs-12 lh-16 fw-600 text-grayscale--500'>{data.liberacaoDeCredito.length}</span>
                </div>
                {data.liberacaoDeCredito.map((item: ILiberacaoDeCredito) => (
                  <div key={item.mes} className='d-flex justify-content-between'>
                    <span className='fs-12 lh-14 text-grayscale--400'>Mês {item.mes}</span>
                    <span className='fs-12 lh-16 fw-600 text-grayscale--500'>{item.valor}</span>
                  </div>
                ))}
              </BoxConditions>
              <StarterTimeline>
                <TitleDiv className='d-flex align-items-center pb-1'>
                  <DotSymbol />
                  <span className='fs-16 lh-20 fw-700 text-grayscale--500 pl-3'>Início do Contrato</span>
                </TitleDiv>
                <BoxDescription>
                  <p className='fs-14 lh-18 text-grayscale--400'>Antes da primeira liberação, é pago R$1.106*, referente ao Risco de Engenharia.</p>
                  <span className='fs-14 lh-18 text-grayscale--400'>*Valor referente a essa simulação</span>
                </BoxDescription>
              </StarterTimeline>
              {data.simuladorMensal.map((item: ISimuladorMensal) => (
                <MonthTax key={item.mes}>
                  <TitleDiv className='d-flex align-items-center pb-1'>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                      <span className='fs-10 lh-12 fw-700 text-orange--extra'>Mês</span>
                      <span className='fs-14 lh-18 fw-600 text-orange--extra'>{item.mes}</span>
                    </div>
                    <DotSymbol isOrange />
                    <TitleDescription className='fs-14 lh-18 fw-700 text-orange--extra'>{item.descricao}</TitleDescription>
                  </TitleDiv>
                  <BoxDescription>
                    {item.periodo && (
                      <span className='fs-14 lh-18 text-grayscale--400'>{item.periodo}</span>
                    )}
                    {item.alerta && (
                      <AlertBox className='d-flex mb-2'>
                        <div className='d-flex pr-3'>
                          <OrangeIcon size='MD' color='#6A6C72' icon='info' />
                        </div>
                        <div>
                          <p className='fs-10 lh-12 fw-700 text-grayscale--500 mb-1'>{item.alerta.titulo}</p>
                          <p className='fs-10 lh-12 text-grayscale--500 mb-0'>{item.alerta.descricao}</p>
                        </div>
                      </AlertBox>
                    )}
                  </BoxDescription>
                  <BoxConditions className='mt-2'>
                    <div className='d-flex justify-content-start'>
                      <span className='fs-12 lh-14 fw-700 text-grayscale--500'>Valor Liberado + Incorporado</span>
                    </div>
                    {item.valorLiberadoIncorporado?.map((itemValor: IValorDescription) => (
                      <div className='d-flex justify-content-between' key={itemValor.titulo}>
                        <span className='fs-12 lh-14 text-grayscale--400'>{itemValor.titulo}</span>
                        <span className='fs-12 lh-16 fw-600 text-grayscale--500'>{itemValor.valor}</span>
                      </div>
                    ))}
                    <hr className='mt-1 mb-2' />
                    <div className='d-flex justify-content-between pb-0'>
                      <span className='d-flex align-items-center fs-12 lh-14 text-grayscale--400'>Acumulado</span>
                      <span className='fs-12 lh-16 fw-600 text-orange--extra'>{item.valorLiberadoIncorporadoTotal}</span>
                    </div>
                  </BoxConditions>
                  <BoxConditions className='mt-2'>
                    <div className='d-flex justify-content-start'>
                      <span className='fs-12 lh-14 fw-700 text-grayscale--500'>Valor Aproximado da Parcela Mensal{item.valorParcelaMensalObservacao && '*'}</span>
                    </div>
                    {item.valorParcelaMensal.map((item: IValorDescription) => (
                      <div className='d-flex justify-content-between' key={item.titulo}>
                        <span className='fs-12 lh-14 text-grayscale--400'>{item.titulo}</span>
                        <span className='fs-12 lh-16 fw-600 text-grayscale--500'>{item.valor}</span>
                      </div>
                    ))}
                    <hr className='mt-1 mb-2' />
                    <div className='d-flex justify-content-between pb-0'>
                      <span className='fs-12 lh-14 text-grayscale--400'>{item.valorParcelaMensalTotal.titulo}</span>
                      <span className='fs-12 lh-16 fw-600 text-orange--extra'>{item.valorParcelaMensalTotal.valor}</span>
                    </div>
                  </BoxConditions>
                  {item.valorParcelaMensalObservacao && (
                    <p className='fs-10 lh-12 text-grayscale--400 pt-2'>{item.valorParcelaMensalObservacao}</p>
                  )}
                </MonthTax>
              ))}
              <StarterTimeline>
                <TitleDiv className='d-flex align-items-center pb-1'>
                  <DotSymbol />
                  <TitleDescription className='fs-16 lh-20 fw-700 text-orange--extra pl-3'>{data.ultimaEtapa.descricao}</TitleDescription>
                </TitleDiv>
                <BoxDescription>
                  <p className='fs-14 lh-18 text-grayscale--400'>{data.ultimaEtapa.subDescricao}</p>
                </BoxDescription>
                <BoxConditions className='mt-2'>
                  <div className='d-flex justify-content-start'>
                    <span className='fs-12 lh-14 fw-700 text-grayscale--500'>Valor Liberado + Incorporado</span>
                  </div>
                  <hr className='mt-1 mb-2' />
                  <div className='d-flex justify-content-between pb-0'>
                    <span className='fs-12 lh-14 text-grayscale--400'>Total</span>
                    <span className='fs-12 lh-16 fw-600 text-orange--extra'>{data.ultimaEtapa.valorLiberadoIncorporadoTotal}</span>
                  </div>
                </BoxConditions>
              </StarterTimeline>
              <button className='fs-14 lh-18 fw-700 btn border border-primary mt-4 text-orange--extra' style={{ maxWidth: '312px', minHeight: '50px', borderRadius: '8px', textTransform: 'none' }} onClick={closeModal}>Fechar</button>
            </div>
          </ScrollDiv>
        </div>
      </Container>
    </>
  )
}

export default ModalSimuladorDeParcelas
