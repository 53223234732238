import React from 'react'

// component
import FAQ from 'src/components/Faq'

// Style
import { PerguntasFrequentes } from './style'

// assets
import pageContext from '../../pageContext.json'

const Faq = () => {
  return (
    <PerguntasFrequentes id='perguntas-frequentes' className='bg-white'>
      <div className='container'>
        <div className='row px-md-3'>
          <div className='mb-4'>
            <h3 className='fs-24 lh-28 fs-md-40 lh-md-46 text-grayscale--500'>Dúvidas frequentes sobre o Construcasa</h3>
          </div>
          <FAQ
            id='conta-pj'
            className='px-0'
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            answerData={pageContext.structuredData.faq}
          />
        </div>
      </div>
    </PerguntasFrequentes>
  )
}

export default Faq
