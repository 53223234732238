import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import BgSectionMobile from './../../assets/images/bg-cartao-construcasa-mobile.png'
import BgSectionMD from './../../assets/images/bg-cartao-gradiente-construcasa-md.png'
import BgSectionLG from './../../assets/images/bg-cartao-gradiente-construcasa-lg.png'
import BgSectionXL from './../../assets/images/bg-cartao-gradiente-construcasa-xl.png'

export const Image = styled.div`
  background: url(${BgSectionMobile}) no-repeat;
  background-position: top right;
  background-size: cover;
  width: 100%;
  height: 430px;
`

export const Benefits = styled.div`
  @media ${device.desktopLG} {
     padding-top: 87px !important;
  }
  @media ${device.desktopXL} {
     padding-top: 20px !important;
  }
`

export const Section = styled.section`

  .btn {
    @media ${device.desktopLG} {
      width: 418px;
    }
  }

  @media ${device.tablet} {
    background: url(${BgSectionMD}) no-repeat;
    background-position: bottom right;
    height: 580px;
  } 

  @media ${device.desktopLG} {
    background: url(${BgSectionLG}) no-repeat;
    background-position: bottom right;
    height: 517px;
  } 

  @media ${device.desktopXL} {
    background: url(${BgSectionXL}) no-repeat;
    background-position: bottom right;
    height: 551px;
  }
  `
