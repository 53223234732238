import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

// Data
import dataCarousel from './dataCarousel.json'

// style
import { DeVidaAoProjetoSection, BoxWhiteCarousel } from './style'

interface IDataCarouselType {
  icon: string;
  description: string;
}

type modalProps = {
  setOpenModal: Function;
}

const DeVidaAoProjeto = ({ setOpenModal }: modalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <DeVidaAoProjetoSection className='bg-white' id='comofunciona'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-5 row align-content-xl-center'>
            <h2 className='fs-32 lh-40 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500'>Dê vida ao projeto de construir sua <br /> casa</h2>
            <p className='fs-16 lh-20 text-grayscale--400'>Seu crédito é liberado logo no início da construção e você pode ter novas parcelas na medida em que as etapas da sua obra forem concluídas. </p>
            <div className='d-xl-flex justify-content-xl-start mt-xl-5 d-none d-xl-block'>
              <button
                onClick={ () => {
                  setOpenModal(true)
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_name: 'Ver exemplo de parcelas e taxa',
                    section_name: 'Dê vida ao projeto de construir sua casa',
                  })
                }}
                className='modal-button fs-14 lh-16 text-center text-orange--extra mt-2 mb-4 mt-md-4 mt-lg-0 fw-700'
              >
                Ver exemplo de parcelas e taxa
              </button>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl-7 offset-lg-1 offset-xl-0 pl-xl-5'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 20 }}
              md={{ items: 2, partialVisibilityGutter: 100 }}
              lg={{ items: 1, partialVisibilityGutter: 170 }}
              xl={{ items: 2, partialVisibilityGutter: 50 }}
            >
              { dataCarousel.map((item: IDataCarouselType) => (
                <BoxWhiteCarousel key={item.description}>
                  <div className='bg-white rounded-3 mr-3 d-flex justify-content-between flex-column px-4 py-4'>
                    <OrangeIcon size='MD' color='#FF7A00' icon={item.icon} />
                    <div
                      className=''
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </BoxWhiteCarousel>
                ),
              )}
            </DefaultCarousel>
          </div>
          <div className='col-12 d-flex justify-content-center justify-content-lg-start pt-4 pt-lg-0 pl-lg-0 d-xl-none'>
            <button
              onClick={ () => {
                setOpenModal(true)
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'Ver exemplo de parcelas e taxa',
                  section_name: 'Dê vida ao projeto de construir sua casa',
                })
              }}
              className='modal-button fs-14 lh-16 text-center text-orange--extra mt-2 mb-4 mt-md-4 mt-lg-0 fw-700'
            >
              Ver exemplo de parcelas e taxas
            </button>
          </div>
        </div>
      </div>
    </DeVidaAoProjetoSection>
  )
}

export default DeVidaAoProjeto
