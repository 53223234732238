import React from 'react'

import usePageQuery from '../../pageQuery'

import { Section, ConstrucasaVideoBackground } from './style'

const ConstrucasaVideo = () => {
  const data = usePageQuery()

  return (
    <Section className='bg-white d-flex align-items-top align-items-md-center' id='videoExplicativo'>
      <ConstrucasaVideoBackground
        fluid={[
          data.backgroundConstrucasaVideoMobile.fluid,
          {
            ...data.backgroundConstrucasaVideoMD.fluid,
            media: '(min-width: 768px)',
          },
          {
            ...data.backgroundConstrucasaVideoLG.fluid,
            media: '(min-width: 992px)',
          },
          {
            ...data.backgroundConstrucasaVideoXL.fluid,
            media: '(min-width: 1200px)',
          },
        ]}
      />
      <div className='container py-5'>
        <div className='col-12'>
          <h2 className='fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fw-600 text-orange--extra pb-md-3'>
            Construcasa
          </h2>
          <p className='fs-20 lh-25 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500'>
            <span className='d-block'>A casa dos sonhos</span>
            <span className='d-block'>já vive em você.</span>
            <span className='d-block fw-700'>É hora de realizar!</span>
          </p>
        </div>
      </div>
    </Section>
  )
}

export default ConstrucasaVideo
