import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      advantagesConstrucasaMobile: imageSharp(fluid: {originalName: { regex: "/advantages-construcasa-mobile/" }}) {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      womanAndManCalculatingConstrucasa: imageSharp(fluid: {originalName: { regex: "/advantages-construcasa-desktop/" }}) {
        fluid(maxWidth: 285, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      construcasaHeroBanner: imageSharp(fluid: {originalName: { regex: "/construcasa-hero-banner/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundConstrucasaVideoMobile: imageSharp(fluid: {originalName: { regex: "/background-construcasa-video-mobile/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundConstrucasaVideoMD: imageSharp(fluid: {originalName: { regex: "/background-construcasa-video-md/" }}) {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundConstrucasaVideoLG: imageSharp(fluid: {originalName: { regex: "/background-construcasa-video-lg/" }}) {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundConstrucasaVideoXL: imageSharp(fluid: {originalName: { regex: "/background-construcasa-video-xl/" }}) {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
