import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'

export const DeVidaAoProjetoSection = styled.section`
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 56px 0;
  }

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  .modal-button {
    border: 1px solid ${orange[200]};
    background-color: ${white};
    padding: 16px 32px;
    width: 312px;
    border-radius: 8px;
    cursor: pointer;
    @media ${device.tablet} {
      padding: 12px 32px;
      width: 444px;
    }
    @media ${device.desktopLG} {
      padding: 16px 32px;
      width: 360px;
    }
    @media ${device.desktopXL} {
      width: 435px;
    }
  }
`

export const BoxWhiteCarousel = styled.div`

  > div {
    min-height: 259px;
    border: 1px solid #B6B7BB;
    p {
      font-family: 'Sora';
    }
  }
  li.react-multi-carousel-dot {
    margin: 0;
  }
`
