import React from 'react'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import Summary from 'src/components/Summary'

import { Section } from './style'

const Links = [
  {
    text: 'Simulador',
    redirect: true,
    to: 'https://imobiliario.inter.co/simulador-construcasa/?utm_source=lpconstrucasa&utm_medium=botao&utm_campaign=sumario&utm_content=lp#/',
  },
  {
    text: 'Como funciona',
    to: '#comofunciona',
  },
  {
    text: 'Vantagens',
    to: '#vantagens',
  },
  {
    text: 'FAQ',
    to: '#perguntas-frequentes',
  },
]

const WhatAreYouLookingFor = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <Section id='construcasa-sumario' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Summary
              icon='userdoubt'
              title='O que você procura?'
              links={Links}
              section='dobra_2'
              sendDatalayerEvent={sendDatalayerEvent}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhatAreYouLookingFor
