import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  position: relative;
  min-height: 500px;
`

export const BackgroundDiv = styled.div`
`

export const ConstrucasaVideoBackground = styled(BackgroundImage)`
  position: absolute !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  min-height: 500px;

  @media ${device.tablet} {
    min-height: 500px
  }

  @media ${device.desktopXL} {
    min-height: 546px;
  }
`

export const VideoPlayImage = styled.div`
  position: absolute;
  left: 17vh;
  top: 30vh;

  @media ${device.tablet} {
    left: 33vh;
    top: 17vh;
  }

  @media ${device.desktopLG} {
    left: 59vh;
    top: 21vh;
  }

  @media ${device.desktopXL} {
    left: 72vh;
    top: 19vh;
  }

 div {
   display: flex;
   justify-content: center;
 }
`
