import React from 'react'
import Img from 'gatsby-image'
import Icon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { FinanciamentoDeConstrucaoSection } from './style'

import usePageQuery from '../../pageQuery'
interface IFinanciamentoDeConstrucao {
  utm: string | boolean;
}

const FinanciamentoDeConstrucao = ({ utm }: IFinanciamentoDeConstrucao) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://imobiliario.inter.co/simulador-construcasa/'

  return (
    <FinanciamentoDeConstrucaoSection className='bg-orange' id='simulador'>
      <Img fluid={data.advantagesConstrucasaMobile.fluid} className='mr-0 d-block d-sm-none' alt='Homem e mulher fazendo calculo olhando a planta da construção' />
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-sm-6 col-lg-5'>
            <h2 className='fs-32 lh-40 fs-md-32 fs-lg-40 lh-lg-50 text-white text-left pt-4 pt-md-0'>Financiamento de <br />construção 100% personalizado</h2>
            <p className='fs-16 lh-19 text-white mb-5 text-left'>
              Confira agora a sua simulação aproximada e converse com nossos especialistas para encontrar as melhores soluções pra você.
            </p>
            <div className='d-block'>
              <a
                href={url + utm}
                target='_blank'
                rel='noreferrer'
                className='styled-button border border-white rounded-2 fs-14 lh-17 fw-600 text-none d-flex justify-content-between'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_08',
                    element_action: 'click button',
                    element_name: 'Iniciar simulação',
                    section_name: 'Financiamento de construção 100% personalizado',
                    redirect_url: url + utm,
                  })
                }}
              >
                <span>
                  Iniciar simulação
                </span>
                <Icon size='MD' icon='arrow-right' color='#FFF' />
              </a>
            </div>
          </div>
          <div className='d-none d-sm-block col-sm-5 col-lg-5 col-xl-5'>
            <Img
              fluid={data.womanAndManCalculatingConstrucasa.fluid}
              alt='Homem e mulher fazendo calculo olhando a planta da construção'
            />
          </div>
        </div>
      </div>
    </FinanciamentoDeConstrucaoSection>
  )
}

export default FinanciamentoDeConstrucao
