import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { VantagensDoEmprestimoSection } from './style'

interface IVantagensDoEmprestimo {
  utm: string | boolean;
}

const VantagensDoEmprestimo = ({ utm }: IVantagensDoEmprestimo) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://imobiliario.inter.co/simulador-construcasa/'

  return (
    <VantagensDoEmprestimoSection className='bg-white' id='vantagens'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-sm-6 col-lg-5'>
            <h2 className='fs-32 lh-40 fs-md-32 fs-lg-40 lh-lg-50 text-grayscale--500 text-left'>Vantagens do <br />empréstimo para construções:</h2>
            <p className='fs-16 lh-19 text-grayscale--400 mb-5 text-left'>Cada etapa da sua obra é acompanhada por uma equipe especializada do Inter e, de acordo com a evolução, você garante mais crédito para as próximas fases.</p>
            <div className='d-none d-sm-block'>
              <a
                href={url + utm}
                target='_blank'
                rel='noreferrer'
                className='styled-button btn btn-orange--extra rounded-2 fs-14 lh-17 fw-600 text-none py-24 px-16'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    element_action: 'click button',
                    element_name: 'Simular financiamento',
                    section_name: 'Vantagens do empréstimo para construções:',
                    redirect_url: url + utm,
                  })
                }}
              >
                Simular financiamento
              </a>
            </div>
          </div>
          <div className='col-12 col-sm-6 col-lg-7'>
            <div className='row'>
              <div className='col-12 col-lg-12'>
                <div className='border border-grayscale--400 d-flex align-items-center rounded-3 py-3 py-lg-4 px-4 px-lg-5 mb-3 mb-lg-4'>
                  <span><Icon size='MD' icon='house-financial' color='#FF7A00' /></span>
                  <div className='flex flex-column ml-4 ml-lg-5'>
                    <p className='fs-16 fw-600 lh-19 fs-lg-18 lh-lg-20 text-grayscale--500 mb-2'>
                      Financiamos até 80% da sua construção
                    </p>
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500 mb-0'>
                      As liberações de crédito acompanham cada etapa da sua obra
                    </p>
                  </div>
                </div>
                <div className='border border-grayscale--400 d-flex align-items-center rounded-3 py-3 py-lg-4 px-4 px-lg-5 mb-3 mb-lg-4'>
                  <span><Icon size='MD' icon='agenda' color='#FF7A00' /></span>
                  <div className='flex flex-column ml-4 ml-lg-5'>
                    <p className='fs-16 fw-600 lh-19 fs-lg-18 lh-lg-20 text-grayscale--500 mb-2'>Até 20 anos para pagar</p>
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500 mb-0'>
                      Você tira o projeto do papel sem pesar o seu bolso
                    </p>
                  </div>
                </div>
                <div className='border border-grayscale--400 d-flex align-items-center rounded-3 py-3 py-lg-4 px-4 px-lg-5'>
                  <span><Icon size='MD' icon='dollar' color='#FF7A00' /></span>
                  <div className='flex flex-column ml-4 ml-lg-5'>
                    <p className='fs-16 fw-600 lh-19 fs-lg-18 lh-lg-20 text-grayscale--500 mb-2'>Financiamento isento de IOF</p>
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500 mb-0'>
                      Condições atrativas para tranformar o seu terreno em um novo lar
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 d-block d-sm-none mt-4'>
            <a
              href={url + utm}
              target='_blank'
              rel='noreferrer'
              className='styled-button btn btn-orange--extra rounded-2 fs-14 lh-17 fw-600 text-none py-24 px-16'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  element_name: 'Simular financiamento',
                  section_name: 'Vantagens do empréstimo para construções:',
                  redirect_url: url + utm,
                })
              }}
            >
              Simular financiamento
            </a>
          </div>
        </div>
      </div>
    </VantagensDoEmprestimoSection>
  )
}

export default VantagensDoEmprestimo
