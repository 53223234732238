import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Image, Section, Benefits } from './style'

const AproveiteInterBlackBeneficios = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://imobiliario.inter.co/simulador-construcasa/'

  return (
    <Section id='aproveite-inter-black-beneficios' className='d-md-flex align-items-center'>
      <Image className='d-md-none' />
      <div className='container'>
        <div className='row pt-4 pb-5 py-md-0'>
          <div className='col-12 col-md-10 col-lg-6 px-md-3'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 pb-2 pb-md-3 fw-600 text-grayscale--500'>
              Financie sua construção<br className='d-none d-xl-block' /> e aproveite seu cartão Inter Black recheado de benefícios!
            </h2>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 text-grayscale--400 mb-0 mb-md-4 mr-md-2 fw-400'>
              No Inter, seu cartão Black também te dá acesso a<br className='d-none d-xl-block' /> seguros que garantem sua tranquilidade, onde quer<br className='d-none d-xl-block' /> que você esteja.
            </p>
            <div className='d-none d-lg-block col-12 pt-2 px-0'>
              <a
                href={url + '?_ga=2.110359559.30113214.1657682677-1167425853.1657201658'}
                target='_blank'
                rel='noreferrer'
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    element_action: 'click button',
                    element_name: 'Garanta o seu cartão Inter Black',
                    section_name: 'Financie sua construção e aproveite seu cartão Inter Black recheado de benefícios!',
                    redirect_url: url + '?_ga=2.110359559.30113214.1657682677-1167425853.1657201658',
                  })
                }}
              >
                Garanta o seu cartão Inter Black
              </a>
            </div>
          </div>
          <Benefits className='col-12 col-lg-6 py-5 pl-3 pl-lg-2 px-md-4'>
            <div className='row align-items-center pb-3'>
              <OrangeIcon size='MD' color='#ff7A00' icon='airport' />
              <p className='fs-16 fw-700 ml-4 ml-lg-3 mb-0 text-grayscale--500'>
                Sala VIP em Aeroportos
              </p>
            </div>
            <div className='row align-items-center pb-3'>
              <OrangeIcon size='MD' color='#ff7A00' icon='gift' />
              <p className='fs-16 fw-700 ml-4 ml-lg-3 mb-0 text-grayscale--500'>
                Programa Surpreenda
              </p>
            </div>
            <div className='row align-items-center pb-3'>
              <OrangeIcon size='MD' color='#ff7A00' icon='couch' />
              <p className='fs-16 fw-700 ml-4 ml-lg-3 mb-0 text-grayscale--500'>
                Viagens com Conforto
              </p>
            </div>
            <div className='row align-items-center pb-3'>
              <OrangeIcon size='MD' color='#ff7A00' icon='seguros' />
              <p className='fs-16 fw-700 ml-4 ml-lg-3 mb-0 text-grayscale--500'>
                Seguro médico em viagens
              </p>
            </div>
            <div className='row align-items-center pb-3'>
              <OrangeIcon size='MD' color='#ff7A00' icon='handbag' />
              <p className='fs-16 fw-700 ml-4 ml-lg-3 mb-0 text-grayscale--500'>
                Proteção de Bagagem
              </p>
            </div>
            <div className='row align-items-center'>
              <OrangeIcon size='MD' color='#ff7A00' icon='ticket' />
              <p className='fs-16 fw-700 ml-4 ml-lg-3 mb-0 text-grayscale--500'>
                Aproveite o Priceless Cities
              </p>
            </div>
          </Benefits>
          <div className='d-md-none col-12 px-0'>
            <a
              href={url + '?_ga=2.110359559.30113214.1657682677-1167425853.1657201658'}
              className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none'
              target='__blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  element_action: 'click button',
                  element_name: 'Garanta o seu cartão Inter Black',
                  section_name: 'Financie sua construção e aproveite seu cartão Inter Black recheado de benefícios!',
                  redirect_url: url + '?_ga=2.110359559.30113214.1657682677-1167425853.1657201658',
                })
              }}
            >
              Garanta o seu cartão Inter Black
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AproveiteInterBlackBeneficios
