import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const VantagensDoEmprestimoSection = styled.section`
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 56px 0;
  }

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  .styled-button {
    height: 48px;
  }
`
