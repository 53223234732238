import React, { useState, useEffect } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import BreadCrumb from 'src/components/Breadcrumb'
import { Modal } from 'src/components/Modal'
import Popup from 'src/components/Popup'
import { WIDTH_XL } from 'src/utils/breakpoints'

import useWidthResize from 'src/hooks/window/useWidth'
import useSessionStorage from 'src/hooks/window/useSessionStorage'
import useDomReady from 'src/hooks/window/useDomReady'

import { HeroSection } from './style'

interface IHero {
  utm: string | boolean;
}

const Hero = ({ utm }: IHero) => {
  const domReady = useDomReady()
  const windowWidth = useWidthResize(200)
  const isDesktop = windowWidth > WIDTH_XL

  const [ popup, setPopup ] = useSessionStorage('@hide-bancointer-popup-financiamento-imobiliario', false)
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://imobiliario.inter.co/simulador-construcasa/'

  useEffect(() => {
    !isModalPopupOpen && setPopup(true)
  }, [ isModalPopupOpen ])

  const handleOnMouseLeave = () => {
    setIsPopupOpen(true)
  }

  domReady && document.addEventListener('mouseleave', handleOnMouseLeave)

  const PopupExitPage = domReady && !popup && isDesktop && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <Popup closeModal={() => setIsPopupOpen(false)} setPopup={setPopup} pageName='construcasa' />
    </Modal>
  )

  return (
    <HeroSection id='construcasa-hero' className='bg-white d-flex py-5 py-md-0'>
      {PopupExitPage}
      <div className='container'>
        <div className='row align-items-center'>
          <div className='breadcrumd col-12 mb-2 pt-lg-5'>
            <BreadCrumb
              sectionName='Construcasa: construa a sua casa com o Inter'
              type='pra você' text='Financiamento para construção'
              link='/pra-voce/financiamento-imobiliario/construcasa'
              styles='fs-14 lh-16 text-grayscale--400'
            />
          </div>
          <div className='col-12 col-md-8 col-lg-6 mt-2 mt-md-0'>
            <h1 className='fs-32 lh-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 fw-600 pt-md-3 pr-2 pr-md-0 pt-0 pt-md-0'>
              Construcasa: construa <span className='d-lg-block'>a sua casa com o Inter</span>
            </h1>
            <p className='fs-16 lh-20 fs-md-18 mb-md-3 mb-2'>
              Seu terreno em condomínio pode entrar como <span className='d-lg-block'>garantia no financiamento de até 80% da construção <span className='d-xl-block'>da sua casa.</span></span>
            </p>
            <a
              href={url + utm}
              target='_blank' rel='noreferrer'
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none text-center mt-4'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: 'Simular financiamento',
                  section_name: 'Construcasa: construa a sua casa com o Inter',
                  redirect_url: url + utm,
                })
              }}
            >
              Simular financiamento
            </a>
          </div>
        </div>
      </div>
    </HeroSection>
  )
}

export default Hero
