import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

import BackgroundTablet from '../../assets/images/background-tablet.png'
import BackgroundDesktop from '../../assets/svg/background-desktop.svg'
import BackgroundDesktopLg from '../../assets/svg/background-desktop-lg.svg'

export const FinanciamentoDeConstrucaoSection = styled.section`
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 56px 0;
  }

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  background-color:  ${orange.extra};

  @media ${device.tablet} {
    background-color: ${orange.extra};
    background-image: url(${BackgroundTablet});
    background-position: center right;
    background-repeat: no-repeat;
  }

  @media ${device.desktopLG} {
    background-color: ${orange.extra};
    background-image: url(${BackgroundDesktop});
    background-position: center right;
    background-repeat: no-repeat;

  }

  @media ${device.desktopXL} {
    background-color: ${orange.extra};
    background-image: url(${BackgroundDesktopLg});
    background-position: center right -93px;
    background-repeat: no-repeat;
    background-size: 60%;
  }

  .styled-button {
    width: 100%;
    padding: 0 24px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${white};
    background: ${orange.extra}
  }
`
