import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Background Images
import backgroundMobile from '../../assets/images/aproviete-todo-potencial-background-mobile.png'
import backgroundDesktopMD from '../../assets/images/aproviete-todo-potencial-background-md.png'
import backgroundDesktopLG from '../../assets/images/aproviete-todo-potencial-background-lg.png'
import backgroundDesktopXL from '../../assets/images/aproviete-todo-potencial-background-xl.png'

export const AproveiteTodoPotencialSection = styled.section`
  background: url(${backgroundMobile}) center center / cover no-repeat;
  position: relative;
  align-items: initial;
  min-height: 373px;

  @media ${device.tablet} {
    min-height: 240px;
    align-items: center;
    background: url(${backgroundDesktopMD}) center center / cover no-repeat;
    background-size: cover;
  }
  @media ${device.desktopLG} {
    min-height: 278px;
    background: url(${backgroundDesktopLG}) center center / cover no-repeat;
    background-size: cover;
  }
  @media ${device.desktopXL} {
    min-height: 354px;
    align-items: center;
    background: url(${backgroundDesktopXL}) center center / cover no-repeat;
    background-size: cover;
  }
`
