import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white, grayscale } from 'src/styles/colors'

// Hero banner Images
import heroBannerDesktopMD from '../../assets/images/construcasa-background-md.png'
import heroBannerDesktopLG from '../../assets/images/construcasa-background-lg.png'
import heroBannerDesktopXL from '../../assets/images/construcasa-background-xl.png'

export const HeroSection = styled.section`
  position: relative;
  align-items: initial;

  @media ${device.tablet} {
    min-height: 480px;
    align-items: center;
    background: url(${heroBannerDesktopMD}) no-repeat center center;
    background-size: cover;
  }
  @media ${device.desktopLG} {
    min-height: 594px;
    background: url(${heroBannerDesktopLG}) no-repeat center center;
    background-size: cover;
  }
  @media ${device.desktopXL} {
    min-height: 712px;
    align-items: center;
    background: url(${heroBannerDesktopXL}) no-repeat center center;
    background-size: cover;
  }

  @media ${device.desktopXXXL} {
    min-height: 800px;
  }

  .breadcrumd {
    span, a {
      color: ${grayscale[400]} !important;
      @media ${device.tablet} {
          color: ${white} !important;
      }
    }
  }

  h1 {
    color: ${grayscale[500]};
    @media ${device.tablet} {
      color: ${white};
    }
  }

  p {
    color: ${grayscale[400]};
    @media ${device.tablet} {
      color: ${white};
    }
  }
`
