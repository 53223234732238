import React, { useState, useEffect } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import { Modal } from 'src/components/Modal'
import SimuladorDeParcelasModal from './modal-simulacao/_index'

import {
  Hero,
  WhatAreYouLookingFor,
  DeVidaAoProjeto,
  ConstrucasaVideo,
  AproveiteTodoPotencial,
  VantegensDoEmprestimo,
  AproveiteInterBlackBeneficios,
  FinanciamentoDeConstrucao,
  Faq,
} from './sections/_index'

import { Wrapper } from './style'

function Construcasa () {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const utmLinks = domReady && window.location.search
  const [ openModal, setOpenModal ] = useState(false)

  useEffect(() => {
    document.body.style.overflow = openModal ? 'hidden' : 'auto'
  }, [ openModal ])

const SimuladorDeParcelas = domReady && (
  <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
    <SimuladorDeParcelasModal closeModal={() => setOpenModal(false)} />
  </Modal>
)

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {SimuladorDeParcelas}
        <Hero utm={utmLinks} />
        <WhatAreYouLookingFor sendDatalayerEvent={sendDatalayerEvent} />
        <DeVidaAoProjeto setOpenModal={setOpenModal} />
        <ConstrucasaVideo />
        <AproveiteTodoPotencial />
        <VantegensDoEmprestimo utm={utmLinks} />
        <AproveiteInterBlackBeneficios />
        <FinanciamentoDeConstrucao utm={utmLinks} />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default Construcasa
